<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg12>
        <v-card color="#F7F7F7" tile flat>
          <v-layout wrap justify-center pa-4>
            <v-flex xs12 pt-6>
              <span style="font-family: poppinsbold; font-size: 20px">
                Add Closedown
              </span>
            </v-flex>
            <!-- <v-flex xs12 pt-4>
              <span style="color: #9e9e9e; font-family: poppinslight">
                Current Date
              </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <span style="font-family: poppinsregular">
                {{ formatDate(date) }}
              </span>
            </v-flex> -->
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> Start Date </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startdate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startdate"
                  no-title
                  scrollable
                  @change="checkDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startdate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> End Date </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="enddate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="enddate"
                  no-title
                  scrollable
                  @change="checkDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(enddate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex>
              <span style="font-family: poppinsbold">Select Programs</span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-combobox
                v-model="program"
                clearable
                :items="programs"
                item-text="progName"
                item-value="_id"
                dense
                return-object
                multiple
                outlined
                small-chips
              ></v-combobox>
            </v-flex>
            <v-flex xs12 md12 pt-4>
              <span style="font-family: poppinsbold"> Reason </span>
            </v-flex>
            <v-flex xs12 md12 align-self-center>
              <v-text-field
                outlined
                v-model="name"
                label="Name"
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12>
              <v-btn color="#FDCE48" @click="add()">
                <span style="font-family: poppinsregular; color: white">
                  SUBMIT</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["date"],
  data() {
    return {
      
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      name: null,
      startdate: new Date().toISOString().substr(0, 10),
      enddate: new Date().toISOString().substr(0, 10),
      dates: new Date().toISOString().substr(0, 10),
      menu: false,
      menu1: false,
      programs: [],
      program: "",
    };
  },
  watch: {
    selectedSlot: {
      handler(val) {
        if (val.seats < this.totalGuest) {
          for (var i = 0; i < this.guest.length; i++) {
            this.guest[i].value = 0;
          }
        }
      },
      deep: true,
    },
    guest: {
      handler(val) {
        this.totalGuest = val.reduce(function (prev, cur) {
          return prev + cur.value;
        }, 0);
      },
      deep: true,
    },
  },
  mounted() {
    this.getPrograms("started");
  },
  methods: {
    checkDate() {
      if (this.startdate < this.dates) {
        this.startdate = this.dates;
      }
      if (this.enddate < this.dates) {
        this.enddate = this.dates;
      }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var month = dt.getMonth();
      dt = dt.toString();
      var strTime = year + "-" + month + "-" + day;
      return strTime;
    },
    getPrograms(val) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/getlist",
        params: { programmestatus: val },
      })
        .then((response) => {
          this.appLoading = false;
          this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "POST",
        url: "/closed/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          start: this.startdate,
          end: this.enddate,
          remark: this.name,
          programmes: this.program,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg=response.data.msg
            this.showSnackBar=true
            this.$router.push('/Admin/viewClosed')
          }
          this.startdate = new Date().toISOString().substr(0, 10);
          this.enddate = new Date().toISOString().substr(0, 10);
          this.name = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style >
input {
  color: black !important;
}
</style>